



























import Vue from "vue";
import { getModule } from "vuex-module-decorators";
import UserModule from "@/store/modules/UserModule";
import store from "@/store";

export default Vue.extend({
  // A little scnackbar to show some feedback for the user.
  // It can be triggert with a store commit message.
  //  this.userModule.showUserFeedback(userFeedback);
  // Params:
  // userFeedback = {
  //     color: string, e.g. "info", "success", "warn", "error", "primary", ...
  //     message: string, e.g. "Hello World!"
  //     timeout: integer, e.g. 2000 milliseconds
  // }
  name: "UserFeedback",

  data() {
    return {
      userModule: getModule(UserModule, store),
    };
  },

  computed: {
    isUserInfo: {
      get(): boolean | undefined {
        return this.userModule.userFeedback.isActive;
      },
      set(value: boolean) {
        this.userModule.setIsUserFeedback(value);
      },
    },
  },
});
