import Vue from "vue";
import VueI18n, { LocaleMessages } from "vue-i18n";

Vue.use(VueI18n);

function loadLocaleMessages(): LocaleMessages {
  const locales = require.context(
    "./locales",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages: LocaleMessages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

const numberFormats = {
  "en-US": {
    currency: {
      style: "currency",
      currency: "USD",
    },
  },
  de: {
    currency: {
      style: "currency",
      currency: "EUR",
    },
  },
  "en-GB": {
    currency: {
      style: "currency",
      currency: "GBP",
    },
  },
};

const dateTimeFormats = {
  "en-US": {
    short: {
      year: "numeric",
      month: "short",
    },
    long: {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    },
  },
  de: {
    short: {
      year: "numeric",
      month: "short",
    },
    long: {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    },
  },
  "en-GB": {
    short: {
      year: "numeric",
      month: "short",
    },
    long: {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    },
  },
};

export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || "en-US",
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en-US",
  messages: loadLocaleMessages(),
  numberFormats,
  dateTimeFormats,
});
