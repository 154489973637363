enum Mutations {
  // mutation types
  SHOW_USER_FEEDBACK = "showUserFeedback",
  SET_IS_USER_FEEDBACK = "setIsUserFeedback",

  GET_MODULE_DATA = "getModuleData",

  SET_LANGUAGE = "setLanguage",
  SET_ADMIN_STATE_MODE = "setAdminStateMode",
  SET_EXTERNAL_CONFIG = "setExternalConfig",

  GET_BACKEND_VERSION = "getBackendVersion",

  SET_RIGHT_LOGO = "setRightLogo",
  SET_LEFT_LOGO = "setLeftLogo"
}

export { Mutations };
