import { Module, Mutation, VuexModule } from "vuex-module-decorators";
import { Mutations } from "@/store/enums/StoreEnums";

export class UserFeedback {
  isActive?: boolean = false;
  color = "info";
  message = "Infomessage";
  timeout = 2000;
}

@Module({ name: "user" })
export default class UserModule extends VuexModule {
  userFeedback = {} as UserFeedback;

  /**
   * Sets just the state of the user feedback.
   * @param value
   */
  @Mutation
  [Mutations.SET_IS_USER_FEEDBACK](value: boolean): void {
    this.userFeedback.isActive = value;
  }

  /**
   *  Enables the userfeedback. More info about the 'userFeedback'
   *  can be found in the component UserFeedback.vue
   * @param userFeedback
   */
  @Mutation
  [Mutations.SHOW_USER_FEEDBACK](userFeedback: UserFeedback): void {
    userFeedback.isActive = true;
    this.userFeedback = userFeedback;
  }
}
