import Layout from "./Layout";

export default class HomePageLayoutData {
  theme = "";
  leftLogo = "";
  rightLogo = "";
  modules = new Array<Layout>();

  constructor(theme?: string,leftLogo?: string,rightLogo?: string, modules?: Layout[]) {
    this.theme = theme ? theme : "";
    this.leftLogo = leftLogo ? leftLogo : "";
    this.rightLogo = rightLogo ? rightLogo : "";
    this.modules = modules ? modules : new Array<Layout>();
  }
}
