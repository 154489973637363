import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

const Home = () => import(/* webpackChunkName: "home" */ "../views/Home.vue");
const Unauthorized = () =>
  import(/* webpackChunkName: "unauthorized" */ "../views/Unauthorized.vue");
const FourOhFour = () =>
  import(/* webpackChunkName: "fourOhFour" */ "../views/404.vue");

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  { path: "/", redirect: "/home" },
  {
    path: "/home",
    name: "Home",
    component: Home,
    meta: {
      isAuthenticated: true,
    },
  },
  {
    path: "/unauthorized",
    name: "Unauthorized",
    component: Unauthorized,
  },
  {
    path: "*",
    name: "FourOhFour",
    component: FourOhFour,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta?.isAuthenticated) {
    // Get the actual url of the app, it's needed for Keycloak
    const basePath = window.location.toString();

    if (!Vue.$keycloak.authenticated) {
      // The page is protected and the user is not authenticated. Force a login.
      Vue.$keycloak.login({ redirectUri: basePath });
    } else {
      next();
    }
  } else {
    // This page did not require authentication
    next();
  }
});

export default router;
