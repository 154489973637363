














import Vue from "vue";
import { getModule } from "vuex-module-decorators";
import HomeConfigurationDataModule from "../../store/modules/HomeConfigurationDataModule";

export default Vue.extend({
  // This component describes the main navigation bar logo, shown above every content page.

  name: "LogoTitle",
  data() {
    return {
      homeConfigurationData: getModule(
        HomeConfigurationDataModule,
        this.$store
      ),
    };
  },

  computed: {
    leftLogo(): string {
      return this.$store.getters.leftLogoUrl === null
        ? ""
        : this.$store.getters.leftLogoUrl;
    },

    rightLogo(): string {
      return this.$store.getters.rightLogoUrl === null
        ? ""
        : this.$store.getters.rightLogoUrl;
    },
  },
});
