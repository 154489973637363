const nav = [
  {
    key: "home",
    to: "/home",
    icon: "mdi-home",
    name: "header.home",
  },
  {
    key: "logout",
    icon: "mdi-logout-variant",
    name: "header.logout",
  },
];

export default nav;
