import Vue from "vue";
import Vuex from "vuex";
import UserModule from "./modules/UserModule";
import HomeConfigurationDataModule from "./modules/HomeConfigurationDataModule";
import LanguageGlobalSelectionModule from "./modules/LanguageGlobalSelectionModule";

import { config } from "vuex-module-decorators";
import AdminUserGlobalState from "./modules/AdminUserGlobalState";
import ExternalConfigModule from "./modules/ExternalConfigModule";
import ComponentVersionModule from "./modules/ComponentVersionModule";

// Set rawError to true by default on all @Action decorators
config.rawError = true;

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    user: UserModule,
    homeConfigurationData: HomeConfigurationDataModule,
    languageGlobalSelectionModule: LanguageGlobalSelectionModule,
    adminUserGlobalState: AdminUserGlobalState,
    externalModule: ExternalConfigModule,
    componentVersionModule: ComponentVersionModule
  },
});
