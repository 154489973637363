import axios, { AxiosError } from "axios";
import VueAxios from "vue-axios";
import { AxiosResponse, AxiosRequestConfig } from "axios";
import ErrorDetails from "../models/ErrorDetails";
import Vue from "vue";

/**
 * @description service to call HTTP request via Axios
 */
class ApiService {
  /**
   * @description initialize vue axios
   */
  public static init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = process.env.VUE_APP_API_URL;
    ApiService.initializeRequestInterceptor();
    ApiService.initializeResponseInterceptor();
  }

  public static initializeRequestInterceptor = () => {
    Vue.axios.interceptors.request.use(ApiService.handleRequest);
  };

  public static initializeResponseInterceptor = () => {
    Vue.axios.interceptors.response.use((response: any) => {
      return response;
    }, ApiService.handleError);
  };

  public static handleError = async (error: AxiosError): Promise<void> => {
    // check if axios call fails due to No Internet Connection
    if (error.message == "Network Error") {
      return Promise.reject(
        "Network Error. Please check your internet connection"
      );
    }

    if (error.response?.status === 401) {
      await ApiService.refreshToken();
    }

    const generalError = error;
    if (error.response) {
      if (error.response.status === 502) {
        return Promise.reject("502 Bad Gateway");
      } else if (error.response.status === 503) {
        return Promise.reject("503 The server is currently unavailable.");
      } else {
        generalError.message = `Request failed with status code ${error.response.status}.`;
      }
    } else {
      return Promise.reject(generalError);
    }

    const err = error
      ? error.response.data
        ? error.response.data.error
          ? error.response.data.error.message
          : generalError
        : generalError
      : generalError;

    return Promise.reject(err);
  };

  public static refreshToken = async () => {
    Vue.$keycloak.updateToken(70);
  };

  /**
   * @description Add Authorization Bearer
   * @param config
   */
  public static handleRequest = (
    config: AxiosRequestConfig
  ): AxiosRequestConfig => {
    const headers: any = config?.headers;
    headers["Authorization"] = `Bearer ${Vue.$keycloak.idToken}`;
    return config;
  };

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static async query(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return Vue.axios.get(resource, params).catch((error: ErrorDetails) => {
      throw new Error(`${error}`);
    });
  }
  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param slug: string
   * @returns Promise<AxiosResponse>
   */
  public static async get(
    resource: string,
    slug = "" as string
  ): Promise<AxiosResponse> {
    return Vue.axios.get(`${resource}/${slug}`).catch((error: ErrorDetails) => {
      throw new Error(`${error}`);
    });
  }
  /**
   * @description set the POST HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static async post(
    resource: string,
    params: any
  ): Promise<AxiosResponse> {
    return Vue.axios
      .post(`${resource}`, params)
      .catch((error: ErrorDetails) => {
        throw new Error(`${error}`);
      });
  }
  /**
   * @description send the UPDATE HTTP request
   * @param resource: string
   * @param slug: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static async update(
    resource: string,
    slug: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return Vue.axios
      .put(`${resource}/${slug}`, params)
      .catch((error: ErrorDetails) => {
        throw new Error(`${error}`);
      });
  }
  /**
   * @description Send the PUT HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static async put(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return Vue.axios.put(`${resource}`, params).catch((error: ErrorDetails) => {
      throw new Error(`${error}`);
    });
  }
  /**
   * @description Send the DELETE HTTP request
   * @param resource: string
   * @returns Promise<AxiosResponse>
   */
  public static async delete(resource: string): Promise<AxiosResponse> {
    return Vue.axios.delete(resource).catch((error: ErrorDetails) => {
      throw new Error(`${error}`);
    });
  }
}

export default ApiService;
